import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Route } from 'react-router-dom'

// Material UI
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuIcon from '@material-ui/icons/Menu'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

// Icons
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

// Lib
import { checkIfAdminIsLoggedIn, logOut } from '../lib/adminAuth'

// Sub pages
import Main from './HomepageScreens/Main'
import PytaniaEgzaminacyjneSubScreen from './HomepageScreens/PytaniaEgzaminacyjnePage'
// import UsersManagementSubScreen from './HomepageScreens/UsersManagementSubScreen'
import EditQuestionSubPage from './HomepageScreens/EditQuestionSubPage'
import ProductKeysManagement from './HomepageScreens/ProductKeysManagement'
import AddProductKey from './HomepageScreens/AddProductKey'
import ProductKeyPreview from './HomepageScreens/ProductKeyPreview'

// Styled components
const LogoDrawer = styled.img`
    width: 40px;
    margin-right: 20px;
`
const ContentWrapper = styled.div`
    width: 100%;
    height: calc(100% - 64px);
    @media (max-width: 600px) {
        height: calc(100% - 56px);
    }
    flex-grow: 1;
`
const LoadingWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    display: flex;
    justify-content: center;
    align-items: center;
`

const drawerWidth = 270

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}))

function ResponsiveDrawer(props) {
    const { container } = props
    const classes = useStyles()
    const theme = useTheme()

    // States
    const [isLoading, setIsLoading] = useState(true)
    const [open, setOpen] = useState(false)

    const handleDrawerOpen = () => {
        setOpen(true)
    }
    const handleDrawerClose = () => {
        setOpen(false)
    }

    // Checking if user is logged in
    useEffect(() => {
        checkIfAdminIsLoggedIn().then((r) => {
            console.log(r)
            if (r) setIsLoading(false)
            else props.history.push('/login')
        })
        // eslint-disable-next-line
    }, [])

    const drawer = (
        <>
            <Divider />
            <List>
                {/* <ListItem
                    button
                    onClick={() => {
                        props.history.push('/users-management')
                        setOpen(false)
                    }}
                >
                    <ListItemIcon>
                        <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Użytkownicy'} />
                </ListItem> */}
                <ListItem
                    button
                    onClick={() => {
                        props.history.push('/product-keys')
                        setOpen(false)
                    }}
                >
                    <ListItemIcon>
                        <VpnKeyIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Klucze produktów'} />
                </ListItem>
                <ListItem
                    button
                    onClick={() => {
                        props.history.push('/pytania-egzaminacyjne-chapters')
                        setOpen(false)
                    }}
                >
                    <ListItemIcon>
                        <QuestionAnswerIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Pytania egzaminacyjne'} />
                </ListItem>
            </List>
            <Divider />
            <List>
                <ListItem
                    button
                    onClick={() => {
                        const foo = async () => {
                            await logOut()
                            props.history.push('/login')
                        }
                        foo()
                    }}
                >
                    <ListItemIcon>
                        <ExitToAppIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Wyloguj się'} />
                </ListItem>
            </List>
        </>
    )

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerOpen}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    <LogoDrawer src={'/logo-drawer.png'} />
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={open}
                        onClose={handleDrawerOpen}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        <div className={classes.drawerHeader}>
                            <IconButton onClick={handleDrawerClose}>
                                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                            </IconButton>
                        </div>
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content} style={{ padding: '4px' }}>
                <div className={classes.toolbar} />
                <ContentWrapper>
                    {isLoading ? (
                        <LoadingWrapper>
                            <CircularProgress />
                        </LoadingWrapper>
                    ) : (
                        <>
                            <Route
                                path={`/pytania-egzaminacyjne-chapters/:filter`}
                                component={PytaniaEgzaminacyjneSubScreen}
                            />
                            <Route
                                exact
                                path={`/pytania-egzaminacyjne-chapters`}
                                component={PytaniaEgzaminacyjneSubScreen}
                            />
                            {/* <Route path={`/pytania-egzaminacyjne/:id`} component={EditQuestionSubPage} /> */}
                            <Route path={`/pytania-egzaminacyjne/:id/:chapter`} component={EditQuestionSubPage} />
                            <Route path={`/pytania-egzaminacyjne/:id`} component={EditQuestionSubPage} />
                            <Route exact path={`/product-keys-add`} component={AddProductKey} />
                            <Route path={`/product-keys/:id`} component={ProductKeyPreview} />
                            <Route exact path={`/product-keys`} component={ProductKeysManagement} />
                            {/* <Route path={`/users-management`} component={UsersManagementSubScreen} /> */}
                            <Route exact path="/" component={Main} />
                        </>
                    )}
                </ContentWrapper>
            </main>
        </div>
    )
}

export default ResponsiveDrawer
