import React, {useEffect} from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

// Screens
import LoginPage from './screens/LoginPage'
import HomePage from './screens/HomePage'

// Configuration
import config from './config.js'

// Material UI
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#808080',
            main: '#262626',
            dark: '#000000',
            contrastText: '#fff',
        },
    },
})

const App = () => {
    useEffect(()=>{
        console.log(`process: ${process.env.NODE_ENV}, apiUrl: ${config.apiUrl}`)
    }, [])
    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Switch>
                    <Route path="/login" component={LoginPage} />
                    <Route path="/" component={HomePage} />
                </Switch>
            </BrowserRouter>
        </ThemeProvider>
    )
}

ReactDOM.render(<App />, document.getElementById('root'))
