import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

// Lib
import pytaniaFilters from '../../lib/data/chaptes'

// Material UI
import CircularProgress from '@material-ui/core/CircularProgress'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'

// Lib
import config from '../../config'

// Styled components
const Main = styled.div`
    width: calc(100vw - 270px);
    @media (max-width: 600px) {
        width: 100vw;
    }
    height: 100vh;
    max-height: calc(100% - 80px);
    display: flex;
    flex-direction: row;
    position: fixed;
    overflow-y: scroll;
`
const MainInner = styled.div`
    height: 100%;
    width: 100%;
    min-width: 900px;
    display: flex;
    flex-direction: row;
    display: absolute;
    top: 0px;
    left: 0px;
`
const Column = styled.div`
    width: 50%;
    height: 100%;
    max-height: 100%;
    overflow-x: scroll;
    border-right: 2px solid lightgrey;
`
const LoadingWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    display: flex;
    justify-content: center;
    align-items: center;
`
const TableRowStyled = styled(TableRow)`
    cursor: pointer;
    :hover {
        background-color: lightgray;
    }
`
const NoFilterSelected = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`

// Fetching data
const PytaniaEgzaminacyjneSubScreen = (props) => {
    const history = useHistory()
    const [isLoadingQuestions, setIsLoadingQuestions] = useState(false)
    const [questionsData, setQuestionData] = useState([])

    // Fetching data
    const _fetchQuestions = () => {
        setIsLoadingQuestions(true)

        fetch(config.apiUrl + '/admin/get-all-pe-questions', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
            .then((res) => {
                res.json().then((data) => {
                    setQuestionData(data)
                    setIsLoadingQuestions(false)
                })
                }
            )
            .catch((err) => console.log(err))
    }

    // Initial data fetch
    useEffect(() => {
        _fetchQuestions()
    }, [])

    return (
        <Main>
            <MainInner>
                <Column style={{ width: '35%' }}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nazwa działu:</TableCell>
                                    <TableCell align="center">Ilość pytań({questionsData.length}):</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {pytaniaFilters.map((chapter, index) => (
                                    <TableRowStyled
                                        onClick={() => {
                                            // setChapterFilter(chapter.filter)
                                            history.push(`/pytania-egzaminacyjne-chapters/${chapter.filter}`)
                                        }}
                                        key={index}
                                    >
                                        <TableCell>{chapter.friendlyName}</TableCell>
                                        <TableCell align="center">
                                            {questionsData.length === 0
                                                ? '0'
                                                : questionsData.filter(
                                                    (question) => question.chaptersArray.indexOf(chapter.filter) > -1
                                                ).length}
                                        </TableCell>
                                    </TableRowStyled>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Column>
                <Column style={{ width: '65%' }}>
                    {isLoadingQuestions ? (
                        <LoadingWrapper>
                            <CircularProgress />
                        </LoadingWrapper>
                    ) : (
                        <>
                            {!props.match.params.filter ? (
                                <NoFilterSelected>Wybierz dział</NoFilterSelected>
                            ) : (
                                <>
                                    <TableContainer component={Paper}>
                                        <Table size="small" aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Pytanie:</TableCell>
                                                    <TableCell align="center">Numer pytania</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {questionsData
                                                    .filter(
                                                        (question) =>
                                                            question.chaptersArray.indexOf(props.match.params.filter) >
                                                            -1
                                                    )
                                                    .sort((a, b) => {
                                                        if (
                                                            parseInt(a.questionId.replace(/\./g, '')) ===
                                                            parseInt(b.questionId.replace(/\./g, ''))
                                                        )
                                                            return 0
                                                        else if (
                                                            parseInt(a.questionId.replace(/\./g, '')) >
                                                            parseInt(b.questionId.replace(/\./g, ''))
                                                        )
                                                            return 1
                                                        else return -1
                                                    })
                                                    .map((question, index) => (
                                                        <TableRowStyled
                                                            onClick={() =>
                                                                history.push(`/pytania-egzaminacyjne/${question._id}`)
                                                            }
                                                            key={index}
                                                        >
                                                            <TableCell>{question.question}</TableCell>
                                                            <TableCell align="center">{question.questionId}</TableCell>
                                                        </TableRowStyled>
                                                    ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Fab
                                        onClick={() =>
                                            history.push(
                                                `/pytania-egzaminacyjne/new-question/${props.match.params.filter}`
                                            )
                                        }
                                        style={{ position: 'fixed', right: '50px', bottom: '50px' }}
                                        color="primary"
                                        aria-label="add"
                                    >
                                        <AddIcon />
                                    </Fab>
                                </>
                            )}
                        </>
                    )}
                </Column>
            </MainInner>
        </Main>
    )
}
export default PytaniaEgzaminacyjneSubScreen
