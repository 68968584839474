// Config
import config from '../config'

// Checking if admin is logged in
export const checkIfAdminIsLoggedIn = async () => {
    const response = await fetch(config.apiUrl + '/admin/check-login', {
        credentials: 'include',
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    })
        .then((res) =>
            res.json().then((data) => {
                console.log("Login state: ", data.auth)
                return data.auth
            })
        )
        .catch((err) => console.log(err))
    return response
}

// Logging out
export const logOut = async () => {
    const response = await fetch(config.apiUrl + '/admin/logout', {
        credentials: 'include',
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    })
        .then((res) =>
            res.json().then((data) => {
                return data
            })
        )
        .catch((err) => console.log(err))
    return response
}
