import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

// Lib
import config from '../config'
import { checkIfAdminIsLoggedIn } from '../lib/adminAuth'

// Material ui
import { Paper, TextField, Button } from '@material-ui/core'

// Styled components
const Main = styled.div`
    height: 100vh;
    width: 100vw;
    background-image: url('/login_bg.jpg');
    background-size: cover;
    background-position: center;
`
const MainTint = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.8);
`
const Form = styled.form`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`
const FormWrapper = styled(Paper)`
    padding: 20px;
`
const ZalogujBtt = styled(Button)`
    border-radius: 300px;
`
const Logo = styled.img`
    width: 60px;
    height: 60px;
    margin-bottom: 50px;
`

export const LoginPage = (props) => {
    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')

    useEffect(() => {
        const f = async () => {
            if ((await checkIfAdminIsLoggedIn()) === true) props.history.push('/')
        }
        f()
        // eslint-disable-next-line
    }, [])

    const _login = (e) => {
        console.log('API URL: ', config.apiUrl)
        e.preventDefault()
        fetch(config.apiUrl + '/admin/login', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({ login: login, password: password }),
        })
            .then((res) =>
                res.json().then((data) => {
                    console.log(data)
                    if (data.auth) {
                        props.history.push('/')
                        return
                    } else {
                        alert('Zle hasło')
                    }
                })
            )
            .catch((err) => console.log(err))
    }

    return (
        <Main>
            <MainTint>
                <Logo alt="Pro Morze Logo" src="/logo-drawer.png" />
                <FormWrapper>
                    <Form onSubmit={(e) => _login(e)}>
                        <TextField value={login} onChange={(e) => setLogin(e.target.value)} label="Login" type="text" />
                        <TextField
                            style={{ color: 'white', marginBottom: '20px' }}
                            fullWidth
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            label="Hasło"
                            type="password"
                        />
                        <ZalogujBtt type="submit" variant="contained" color="primary">
                            Zaloguj się
                        </ZalogujBtt>
                    </Form>
                </FormWrapper>
            </MainTint>
        </Main>
    )
}

export default LoginPage
