module.exports = [
    { filter: 'PODSTAWY_BUDOWY_JACHTOW_ZAGLOWYCH', friendlyName: 'Podstawy budowy jachtów żaglowych' },
    { filter: 'PODSTAWY_BUDOWY_JACHTOW_MOTOROWYCH', friendlyName: 'Podstawy budowy jachtów motorowych' },
    { filter: 'TEORIA_ZEGLOWANIA', friendlyName: 'Teoria żeglowania' },
    {
        filter: 'ZASADY_EKSPLOATACJI_I_MANEWROWANIA_JACHTEM_ZAGLOWYM',
        friendlyName: 'Zasady eksploatacji i manewrowania jachtem żaglowym',
    },
    { filter: 'PODSTAWY_LOCJI', friendlyName: 'Podstawy locji' },
    { filter: 'WIADOMOSCI_Z_ZAKRESU_RATOWNICTWA_WODNEGO', friendlyName: 'Wiadomości z zakresu ratownictwa wodnego' },
    { filter: 'WIADOMOSCI_Z_ZAKRESU_METEOROLOGII', friendlyName: 'Wiadomości z zakresu meteorologii' },
    { filter: 'POMOCE_NAWIGACYJNE', friendlyName: 'Pomoce nawigacyjne' },
    { filter: 'OCHRONA_WOD_PRZED_ZANIECZYSZCZENIEM', friendlyName: 'Ochrona wod przed zanieczyszczeniem' },
    { filter: 'PRZEPISY_STERNIK_MOTOROWODNY', friendlyName: 'Przepisy sternik motorowodny' },
    { filter: 'PRZEPISY_ZEGLARZ_JACHTOWY', friendlyName: 'Przepisy żeglarz jachtowy' },
    // { filter: 'PRZEPISY', friendlyName: 'Przepisy' },
    { filter: 'ETYKIETA_JACHTOWA', friendlyName: 'Etykieta jachtowa' },
    { filter: 'MANEWROWANIE_JACHTEM_MOTOROWYM', friendlyName: 'Manewrowanie jachtem motorowym' },
    { filter: 'SILNIKI_I_UKLADY_NAPEDOWE', friendlyName: 'Silniki i układy napędowe' },
    { filter: 'ZASADY_PROWADZENIA_SKUTEROW_WODNYCH', friendlyName: 'Zasady prowadzenia skuterów wodnych' },
    { filter: 'ROZDZIAL_TESTOWY_ZEGLARZ_JACHTOWY', friendlyName: 'Darmowy rozdział testowy żeglarz jachtowy' },
    { filter: 'ROZDZIAL_TESTOWY_STERNIK_MOTOROWODNY', friendlyName: 'Darmowy rozdział testowy sternik motorowodny' },

    { filter: 'JSM_PODSTAWY_BUDOWY_MORSKICH_JACHTOW_ZAGLOWYCH', friendlyName: 'JSM Podstawy budowy morskich jachtów żaglowych:' },
    { filter: 'JSM_LOCJA', friendlyName: 'JSM Locja:' },
    { filter: 'JSM_NAWIGACJA', friendlyName: 'JSM Nawigacja:' },
    { filter: 'JSM_METEOROLOGIA', friendlyName: 'JSM Meteorologia' },
    { filter: 'JSM_SYGNALIZACJA_I_LACZNOSC', friendlyName: 'JSM Sygnalizacja i łączność' },
    { filter: 'JSM_RATOWNICTWO', friendlyName: 'JSM Ratownictwo' },
    { filter: 'JSM_PLANOWANIE_REJSU', friendlyName: 'JSM Planowanie rejsu' },
    { filter: 'JSM_PRZEPISY', friendlyName: 'JSM Podstawowe przepisy prawa drogi na morskich i śródlądowych drogach wodnych' },

    // { filter: 'MSM_PRZEPISY', friendlyName: 'MSM OLD' },
    { filter: 'MSM_PODSTAWY_BUDOWY_MORSKICH_JACHTOW', friendlyName: 'MSM Budowa morskich jachtów motorowych' },
]
