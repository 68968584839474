import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import Dropzone from 'react-dropzone'

// Config
import config from '../../config'

// Material UI
import Paper from '@material-ui/core/Paper'
import CircularProgress from '@material-ui/core/CircularProgress'
import Divider from '@material-ui/core/Divider'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Select from '@material-ui/core/Select'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import BackupIcon from '@material-ui/icons/Backup'
import SaveIcon from '@material-ui/icons/Save'
import DeleteIcon from '@material-ui/icons/Delete'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import Chip from '@material-ui/core/Chip'

// Lib
import chapters from '../../lib/data/chaptes'

// Styled components
const Main = styled.div`
    width: 100%;
    height: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    overflow-y: scroll;
`
const EditWrapper = styled(Paper)`
    width: 100%;
    height: auto;
    max-width: 600px;
    padding: 20px;
    @media (max-width: 600px) {
        padding-left: 5px;
        padding-right: 5px;
    }
`
const LoadingWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    display: flex;
    justify-content: center;
    align-items: center;
`
const ImageWrapper = styled.div`
    width: 100%;
    height: 300px;
    background-color: #f1f1f2;
    border-radius: 5px;
    padding: 10px;
`
const Image = styled.div`
    width: 100%;
    height: 100%;
    background-image: url('${(props) => props.src}');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
`
const DropZoneLook = styled.section`
    width: 300px;
    height: 100px;
    /* background-color: #f1f1f2; */
    border-radius: 5px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
`
const ChaptersManagementWrapper = styled.div`
    width: 100%;
    /* border: 2px solid red; */
`
const ChaptersChipsWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
`
const ExportWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    flex-wrap: nowrap;
    margin-top: 10px;
`

const EditQuestionSubPage = (props) => {
    const history = useHistory()
    const [isQuestionLoading, setIsQuestionLoading] = useState(true)
    const [questionData, setQuestionData] = useState({})
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const [isNewQuestion, setIsNewQuestion] = useState(false)
    const [selectExport, setSelectExport] = useState('')
    const { id, chapter } = props.match.params

    // Fetching question data
    const _getQuestionData = () => {
        setIsQuestionLoading(true)
        if (id === 'new-question') {
            setIsNewQuestion(true)
            setQuestionData({
                questionId: '',
                rightAnswer: '',
                wrongAnswers: ['', ''],
                withImg: false,
                chapter: chapter,
                chaptersArray: [chapter],
            })
            setIsQuestionLoading(false)
            return
        }
        fetch(config.apiUrl + `/admin/get-pe-question/${id}`, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
            .then((res) =>
                res.json().then((data) => {
                    if (data) {
                        setQuestionData(data)
                        setIsQuestionLoading(false)
                        return
                    }
                    history.goBack()
                })
            )
            .catch((err) => console.log(err))
    }

    // Initial question data fetch
    useEffect(() => {
        _getQuestionData()
        // eslint-disable-next-line
    }, [])

    // Sending file to server
    const _onUploadFile = (file) => {
        let formData = new FormData()
        formData.append('file', file)
        fetch(config.apiUrl + '/admin/upload-pe-image', {
            credentials: 'include',
            method: 'POST',
            body: formData,
        }).then((res) =>
            res.json().then((data) => {
                if (data.filename) {
                    setQuestionData({ ...questionData, imgName: data.filename, withImg: true })
                    setIsDialogOpen(false)
                }
            })
        )
    }

    // Saving changes
    const _onSaveChanges = () => {
        if (!isNewQuestion) {
            fetch(config.apiUrl + '/admin/update-pe-question', {
                credentials: 'include',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                body: JSON.stringify({ ...questionData }),
            }).then((res) =>
                res.json().then((data) => {
                    if (data.res) {
                        history.goBack()
                    }
                })
            )
        } else {
            fetch(config.apiUrl + '/admin/add-pe-question', {
                credentials: 'include',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                body: JSON.stringify({ ...questionData }),
            }).then((res) =>
                res.json().then((data) => {
                    if (data.res) {
                        history.goBack()
                    }
                })
            )
        }
    }

    // Deleting question
    const _onDeleteQuestion = () => {
        fetch(config.apiUrl + `/admin/delete-pe-question/${questionData._id}`, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        }).then((res) =>
            res.json().then((data) => {
                if (data.res) {
                    history.goBack()
                }
            })
        )
    }

    // Adding chapter to question
    const _onAddChapterToQuestion = () => {
        let newArray = questionData.chaptersArray
        newArray.push(selectExport)
        setQuestionData({ ...questionData, questionsArray: newArray })
    }

    return (
        <>
            {isQuestionLoading ? (
                <LoadingWrapper>
                    <CircularProgress />
                </LoadingWrapper>
            ) : (
                <Main>
                    <EditWrapper elevation={2}>
                        <div style={{ paddingBottom: '15px' }}>
                            <Button onClick={() => history.goBack()} color="primary">
                                <KeyboardBackspaceIcon style={{ marginRight: '12px' }} />
                                Wróć
                            </Button>
                        </div>
                        <Divider style={{ marginBottom: '20px' }} />
                        <TextField
                            style={{ marginTop: '20px' }}
                            multiline
                            fullWidth
                            id="outlined"
                            label="Numer pytania:"
                            variant="outlined"
                            value={questionData.questionId}
                            onChange={(e) => setQuestionData({ ...questionData, questionId: e.target.value })}
                        />
                        <TextField
                            style={{ marginTop: '20px' }}
                            multiline
                            fullWidth
                            id="outlined"
                            label="Pytanie:"
                            variant="outlined"
                            value={questionData.question}
                            onChange={(e) => setQuestionData({ ...questionData, question: e.target.value })}
                        />
                        <TextField
                            style={{ marginTop: '20px' }}
                            multiline
                            fullWidth
                            id="outlined"
                            label="Prawidłowa odpowiedź:"
                            variant="outlined"
                            value={questionData.rightAnswer}
                            onChange={(e) => setQuestionData({ ...questionData, rightAnswer: e.target.value })}
                        />
                        <TextField
                            style={{ marginTop: '20px' }}
                            multiline
                            fullWidth
                            id="outlined"
                            label="Zła odpowiedź 1:"
                            variant="outlined"
                            value={questionData.wrongAnswers[0]}
                            onChange={(e) =>
                                setQuestionData({
                                    ...questionData,
                                    wrongAnswers: [e.target.value, questionData.wrongAnswers[1]],
                                })
                            }
                        />
                        <TextField
                            style={{ marginTop: '20px' }}
                            multiline
                            fullWidth
                            id="outlined"
                            label="Zła odpowiedź 2:"
                            variant="outlined"
                            value={questionData.wrongAnswers[1]}
                            onChange={(e) =>
                                setQuestionData({
                                    ...questionData,
                                    wrongAnswers: [questionData.wrongAnswers[0], e.target.value],
                                })
                            }
                        />
                        <Divider style={{ marginTop: '30px' }} />
                        <div
                            style={{
                                marginTop: '12px',
                                marginBottom: '12px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography>Zdjęcie</Typography>
                            <Switch
                                checked={questionData.withImg}
                                color="primary"
                                onChange={() => {
                                    if (!questionData.withImg) {
                                        setIsDialogOpen(true)
                                    } else {
                                        setQuestionData({ ...questionData, withImg: false, imgName: undefined })
                                    }
                                }}
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                        </div>
                        <Divider style={{ marginBottom: '20px' }} />
                        <div
                            style={{
                                marginTop: '12px',
                                marginBottom: '12px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography>Większy rozmiar</Typography>
                            <Switch
                                checked={questionData.isBiggerSize}
                                color="primary"
                                onChange={() => {
                                    setQuestionData({ ...questionData, isBiggerSize: !questionData.isBiggerSize })
                                }}
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                            />
                        </div>
                        <Divider style={{ marginBottom: '20px' }} />

                        {/* Chapters management */}
                        <ChaptersManagementWrapper>
                            <ChaptersChipsWrapper>
                                {questionData.chaptersArray &&
                                    questionData.chaptersArray.map((chapter, index) => (
                                        <Chip
                                            key={index}
                                            style={{ margin: '5px' }}
                                            label={
                                                chapters.filter((chapterB) => chapterB.filter === chapter)[0] &&
                                                chapters.filter((chapterB) => chapterB.filter === chapter)[0]
                                                    .friendlyName
                                            }
                                            onDelete={() => {
                                                let newArray = questionData.chaptersArray
                                                // console.log(newArray, questionData)
                                                if (newArray.length > 1) {
                                                    newArray.splice(newArray.indexOf(chapter), 1)
                                                    setQuestionData({ ...questionData, chaptersArray: newArray })
                                                }
                                            }}
                                        />
                                    ))}
                            </ChaptersChipsWrapper>
                            <ExportWrapper>
                                <Select
                                    native
                                    value={selectExport}
                                    onChange={(e) => setSelectExport(e.target.value)}
                                    inputProps={{
                                        name: 'age',
                                        id: 'age-native-simple',
                                    }}
                                >
                                    <option value={''} disabled>
                                        Wybierz dział
                                    </option>
                                    {chapters
                                        .filter((chapter) => questionData.chaptersArray.indexOf(chapter.filter) < 0)
                                        .map((chapter, index) => (
                                            <option key={index} value={chapter.filter}>
                                                {chapter.friendlyName}
                                            </option>
                                        ))}
                                </Select>
                                <Button
                                    style={{ marginLeft: '20px' }}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => _onAddChapterToQuestion()}
                                    disabled={selectExport === ''}
                                >
                                    <ImportExportIcon style={{ marginRight: '5px' }} />
                                    Dodaj
                                </Button>
                            </ExportWrapper>
                        </ChaptersManagementWrapper>

                        <Divider style={{ marginBottom: '20px' }} />
                        {questionData.withImg && questionData.imgName && (
                            <>
                                <ImageWrapper style={{ marginBottom: '12px' }}>
                                    <Image src={config.apiUrl + '/admin/get-pe-image/' + questionData.imgName} />
                                </ImageWrapper>
                            </>
                        )}
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {!isNewQuestion && (
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    style={{ marginRight: '20px' }}
                                    onClick={() => _onDeleteQuestion()}
                                >
                                    <DeleteIcon />
                                    Usuń pytanie
                                </Button>
                            )}
                            <Button variant="contained" color="primary" onClick={() => _onSaveChanges(true)}>
                                <SaveIcon style={{ marginRight: '5px' }} />
                                Zapisz
                            </Button>
                        </div>

                        <Dialog
                            open={isDialogOpen}
                            onClose={() => setIsDialogOpen(!isDialogOpen)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{'Przeciągnij i upuść zdjęcie'}</DialogTitle>
                            <DialogContent>
                                <Dropzone
                                    onDrop={(acceptedFiles) => {
                                        _onUploadFile(acceptedFiles[0])
                                    }}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <DropZoneLook>
                                            <div
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                                {...getRootProps()}
                                            >
                                                <input {...getInputProps()} />
                                                <BackupIcon />
                                            </div>
                                        </DropZoneLook>
                                    )}
                                </Dropzone>
                            </DialogContent>
                        </Dialog>
                    </EditWrapper>
                </Main>
            )}
        </>
    )
}

export default EditQuestionSubPage
