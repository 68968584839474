import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

// Config
import config from '../../config'

// Lib
import products from '../../lib/data/products'

// Material UI
import Paper from '@material-ui/core/Paper'
import CircularProgress from '@material-ui/core/CircularProgress'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Chip from '@material-ui/core/Chip'
import { TextField } from '@material-ui/core'

// Styled components
const Main = styled.div`
    width: 100%;
    height: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    overflow-y: scroll;
    overflow-y: scroll;
`
const LoadingWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    display: flex;
    justify-content: center;
    align-items: center;
`
const PanelMain = styled(Paper)`
    width: 100%;
    max-width: 700px;
    padding: 20px;
    @media (max-width: 600px) {
        padding-top: 20px;
        padding-left: 0px;
        padding-right: 0px;
    }
`
const TableRowStyled = styled(TableRow)`
    :hover {
        background-color: #eceff1;
    }
    cursor: pointer;
`

const ProductKeysManagement = () => {
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const [allProductKeys, setAllProductKeys] = useState([])
    const [searchValue, setSearchValue] = useState('')

    const _LoadProductKeys = () => {
        setIsLoading(true)
        fetch(config.apiUrl + '/admin/get-all-product-keys', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
            .then((res) =>
                res.json().then((data) => {
                    setIsLoading(false)
                    setAllProductKeys(
                        data.sort((a, b) => {
                            if (a.active === false && b.active === true) return 0
                            else return -1
                        })
                    )
                })
            )
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        _LoadProductKeys()
    }, [])

    return (
        <Main>
            {isLoading ? (
                <LoadingWrapper>
                    <CircularProgress />
                </LoadingWrapper>
            ) : (
                <>
                    <PanelMain>
                        <div style={{ paddingBottom: '15px' }}>
                            <Button onClick={() => history.goBack()} color="primary">
                                <KeyboardBackspaceIcon style={{ marginRight: '12px' }} />
                                Wróć
                            </Button>
                        </div>
                        <TextField
                            id="standard-basic"
                            label="Szukaj:"
                            variant="outlined"
                            style={{ width: '100%' }}
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                        />
                        <Divider style={{ marginBottom: '20px' }} />
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Klucz</TableCell>
                                        <TableCell align="center">Stan</TableCell>
                                        <TableCell align="center">Tagi</TableCell>
                                        <TableCell align="right">produkt</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {allProductKeys
                                        .filter((productKeyData) => {
                                            if (productKeyData.tags)
                                                for (let i = 0; i < productKeyData.tags.length; i++)
                                                    if (
                                                        productKeyData.tags[i]
                                                            .toLowerCase()
                                                            .includes(searchValue.toLowerCase())
                                                    )
                                                        return true

                                            if (productKeyData.key.toLowerCase().includes(searchValue.toLowerCase()))
                                                return true

                                            return false
                                        })
                                        .map((productKey, index) => (
                                            <TableRowStyled
                                                key={index}
                                                onClick={() => history.push(`/product-keys/${productKey._id}`)}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {productKey.key}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Chip
                                                        style={{
                                                            backgroundColor: productKey.active ? '#aed581' : '#ff3d00',
                                                            color: productKey.active ? 'black' : 'white',
                                                        }}
                                                        label={productKey.active ? 'Aktywny' : 'Nieaktywny'}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    {productKey.tags &&
                                                        productKey.tags.map((tag, index) => (
                                                            <Chip
                                                                style={{ margin: '1px' }}
                                                                size={'small'}
                                                                key={index}
                                                                label={tag}
                                                            />
                                                        ))}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {products.filter((a) => a.slug === productKey.slug)[0].friendlyName}
                                                </TableCell>
                                            </TableRowStyled>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </PanelMain>
                    <Fab
                        onClick={() => history.push(`/product-keys-add`)}
                        style={{ position: 'fixed', right: 30, bottom: 20 }}
                        color="primary"
                        aria-label="add"
                    >
                        <AddIcon />
                    </Fab>
                </>
            )}
        </Main>
    )
}

export default ProductKeysManagement
