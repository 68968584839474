import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

// Material UI
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'

// Icons
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import PersonIcon from '@material-ui/icons/Person'
import VpnKeyIcon from '@material-ui/icons/VpnKey'

// Styled components
const Main = styled.div`
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    /* border: 2px solid red; */
    cursor: pointer;
`
const Box = styled(Paper)`
    background-color: #e0e0e0;
    width: 200px;
    height: 200px;
    margin: 10px;
    border-radius: 8px;
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: 600px) {
        width: 100%;
    }
`
const Text = styled(Typography)`
    margin-top: 10px;
    text-align: center;
    font-weight: 600;
`

const HomeScreen = () => {
    const history = useHistory()
    return (
        <Main>
            <Box onClick={() => history.push('/pytania-egzaminacyjne-chapters')}>
                <QuestionAnswerIcon fontSize="large" />
                <Text>Pytania egzaminacyjne</Text>
            </Box>
            <Box onClick={() => history.push('/product-keys')}>
                <VpnKeyIcon fontSize="large" />
                <Text>Klucze produktów</Text>
            </Box>
            <Box onClick={() => history.push('/pytania-egzaminacyjne')}>
                <PersonIcon fontSize="large" />
                <Text>Użytkownicy</Text>
            </Box>
        </Main>
    )
}

export default HomeScreen
