import React, { useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

// Config
import config from '../../config'

// Material UI
import Paper from '@material-ui/core/Paper'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import Slider from '@material-ui/core/Slider'
import SaveIcon from '@material-ui/icons/Save'
import TextField from '@material-ui/core/TextField'
import PrintIcon from '@material-ui/icons/Print'
import Chip from '@material-ui/core/Chip'

// Lib
import products from '../../lib/data/products'

// Styled components
export const Main = styled.div`
    width: 100%;
    height: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    overflow-y: scroll;
`
export const PanelMain = styled(Paper)`
    width: 100%;
    max-width: 600px;
    padding: 20px;
    @media (max-width: 600px) {
        padding: 6px;
        /* padding-bottom: 20px; */
        padding-top: 20px;
    }
`
const OptionRow = styled.div`
    width: 100%;
    /* border: 2px solid red; */
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`
const ProductKeyGridWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
`
const ProductKeyMain = styled.div`
    width: 100%;
    max-width: 400px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 12px;
    background-color: white;
    box-shadow: -1px 2px 5px 2px rgba(0, 0, 0, 0.1);
`
const ProductKeyNode = styled.div`
    width: 24%;
    margin-top: 5px;
    margin-bottom: 1px;
    background-color: ${(props) => (props.active ? '#5BEA87' : '#E0E0E0')};
    padding-top: 10%;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    transition: background-color 100ms ease-in-out;
    :hover {
        background-color: ${(props) => (props.active ? '#4FC673' : '#C6C6C6')};
    }
`
const ProductKeyNodeContent = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`
const CheckSymbol = styled.img`
    width: 25px;
    height: 25px;
`
const PrinterWrapper = styled.div`
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`
const TagsMainWrapper = styled.div`
    widht: 100%;
    display: flex;
    justify-content: space-between;
`
const TagsContainer = styled.div`
    flex: 2;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
`

const marks = [
    {
        value: 10,
        label: '2 dni',
        days: 2,
    },
    {
        value: 20,
        label: '3 dni',
        days: 3,
    },
    {
        value: 30,
        label: '4 dni',
        days: 4,
    },
    {
        value: 40,
        label: '5 dni',
        days: 5,
    },
    {
        value: 50,
        label: '7 dni',
        days: 7,
    },
    {
        value: 60,
        label: '14 dni',
        days: 14,
    },
    {
        value: 70,
        label: '30 dni',
        days: 30,
    },
    {
        value: 80,
        label: '60 dni',
        days: 60,
    },
    {
        value: 100,
        label: '365 dni',
        days: 365,
    },
]

// Generating filename
export const generateFileName = (slug, length, amount) => {
    return `Pro Morze ${amount}x${slug} na ${length}dni`
}

const EditProductKey = (props) => {
    const history = useHistory()
    const [productKeyData, setProductKeyData] = useState({})
    const [daysValue, setDaysValue] = useState(50)
    const [isBulk, setIsBulk] = useState(false)
    const [isCSV, setIsCSV] = useState(true)
    const [amountOfBulk, setAmountOfBulk] = useState(10)
    const bulkSettingsDefault = { chooseMode: false, chooseIds: ['x'], printWithBackground: false }
    const [bulkSettings, setBulkSettings] = useState(bulkSettingsDefault)
    const [tagText, setTagText] = useState('')
    const [tags, setTags] = useState([])

    // Add product key
    const _onGenerateProductKey = () => {
        fetch(config.apiUrl + `/admin/generate-product-key`, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                slug: productKeyData.slug,
                licenseLength: marks.filter((product) => product.value === daysValue)[0].days,
                tags: tags,
            }),
        }).then((res) =>
            res.json().then((data) => {
                console.log(data)
                if (data.res) {
                    history.push(`/product-keys/${data.id}`)
                }
            })
        )
    }

    // Generating bulk keys
    function download(filename, text) {
        var pom = document.createElement('a')
        pom.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text))
        pom.setAttribute('download', filename)

        if (document.createEvent) {
            var event = document.createEvent('MouseEvents')
            event.initEvent('click', true, true)
            pom.dispatchEvent(event)
        } else {
            pom.click()
        }
    }
    const _onGenerateBulkKeys = () => {
        const licenseLength = marks.filter((product) => product.value === daysValue)[0].days
        fetch(config.apiUrl + `/admin/generate-bulk-product-keys`, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                slug: productKeyData.slug,
                licenseLength: licenseLength,
                amountOfKeys: amountOfBulk,
                tags: tags,
            }),
        }).then((res) =>
            res.json().then((data) => {
                if (data.res === true) {
                    // download(`Pro Morze-klucze na ${amountOfBulk}dni - ${productKeyData.slug}.csv`, data.content)
                    download(generateFileName(productKeyData.slug, licenseLength, amountOfBulk) + '.csv', data.content)
                }
            })
        )
    }

    // API generate background for printable product keys
    const _downloadPrintableBg = () => {
        window.open(config.apiUrl + '/admin-files/get-product-keys-bg')
    }

    // API generate printable product keys
    const _onGeneratePrintKeys = () => {
        const data = {
            slug: productKeyData.slug,
            licenseLength: marks.filter((product) => product.value === daysValue)[0].days,
            printable: true,
            chooseMode: bulkSettings.chooseMode,
            chosenIds: bulkSettings.chooseIds,
            amountOfKeys: bulkSettings.chooseMode ? bulkSettings.chooseIds.length : amountOfBulk,
            printWithBackground: bulkSettings.printWithBackground,
            tags: tags,
        }

        fetch(config.apiUrl + '/admin/generate-printable-bulk-product-keys', {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify(data),
        }).then((res) =>
            res.json().then((data) => {
                console.log(data)
                if (data.res) {
                    window.open(config.apiUrl + data.redirectUrl)
                    setIsCSV(true)
                    setIsBulk(false)
                }
            })
        )
    }

    // Making array for printable table
    const productKeysNodes = []
    for (let i = 0; i < 48; i++) productKeysNodes.push(i)

    return (
        <Main>
            <PanelMain>
                <div style={{ paddingBottom: '15px' }}>
                    <Button onClick={() => history.goBack()} color="primary">
                        <KeyboardBackspaceIcon style={{ marginRight: '12px' }} />
                        Wróć
                    </Button>
                </div>
                <Divider style={{ marginBottom: '20px' }} />

                <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                    {products.map((product, index) => (
                        <div style={{ width: '100%' }} key={index}>
                            <Divider />
                            <OptionRow>
                                <Switch
                                    checked={productKeyData.slug === product.slug}
                                    onChange={() => setProductKeyData({ ...productKeyData, slug: product.slug })}
                                    value="checkedB"
                                    color="primary"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                                <Typography>{product.friendlyName}</Typography>
                            </OptionRow>
                        </div>
                    ))}
                </div>
                <Divider style={{ marginBottom: '50px' }} />
                <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                    <Slider
                        defaultValue={daysValue}
                        aria-labelledby="discrete-slider-restrict"
                        step={null}
                        marks={marks}
                        onChange={(e, val) => setDaysValue(val)}
                    />
                </div>
                <Divider style={{ marginTop: '15px', marginBottom: '15px' }} />
                <Typography style={{ marginBottom: '10px' }}>Dodaj tagi:</Typography>
                <TagsMainWrapper>
                    <TagsContainer>
                        {tags.map((tag, index) => (
                            <Chip
                                key={index}
                                label={tag}
                                style={{ margin: '2px' }}
                                onDelete={() => {
                                    let newTags = tags
                                    newTags.splice(tags.indexOf(tag), 1)
                                    setTags(newTags)
                                }}
                            />
                        ))}
                    </TagsContainer>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault()
                            if (tagText.length > 0 && tags.indexOf(tagText) === -1) {
                                setTags([...tags, tagText])
                                setTagText('')
                            }
                        }}
                    >
                        <TextField
                            id="standard-basic"
                            size={'small'}
                            label="Tag:"
                            variant="outlined"
                            style={{ width: '150px' }}
                            value={tagText}
                            onChange={(e) => setTagText(e.target.value)}
                        />
                    </form>
                </TagsMainWrapper>
                <Divider style={{ marginBottom: '20px', marginTop: '50px' }} />
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => _onGenerateProductKey(true)}
                        disabled={!(daysValue && productKeyData.slug) || isBulk === true}
                    >
                        <SaveIcon style={{ marginRight: '5px' }} />
                        Zapisz
                    </Button>
                </div>
                <div style={{ width: '100%', marginTop: '20px' }}>
                    <Divider />
                    <OptionRow>
                        <Switch
                            checked={isBulk}
                            onChange={() => setIsBulk(!isBulk)}
                            value="checkedB"
                            color="primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                        <Typography>Generuj wiele kluczy</Typography>
                    </OptionRow>
                    <Divider />
                    {isBulk && (
                        <>
                            <OptionRow>
                                <Switch
                                    checked={!isCSV}
                                    onChange={() => setIsCSV(!isCSV)}
                                    value="checkedB"
                                    color="primary"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                                <Typography>Generuj klucze do wydrukowania</Typography>
                            </OptionRow>
                            <Divider />
                        </>
                    )}
                    {isBulk && isCSV && (
                        <OptionRow style={{ paddingRight: '10px' }}>
                            <TextField
                                id="standard-number"
                                label="Ilość:"
                                type="number"
                                value={amountOfBulk}
                                onChange={(e) => setAmountOfBulk(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{ width: '50px' }}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => _onGenerateBulkKeys()}
                                disabled={!(daysValue && productKeyData.slug) || isBulk === false}
                            >
                                <SaveIcon style={{ marginRight: '5px' }} />
                                Generuj do pliku CSV
                            </Button>
                        </OptionRow>
                    )}

                    {isBulk && !isCSV && (
                        <>
                            <Typography style={{ marginTop: '13px' }}>Szybkie akcje</Typography>
                            <OptionRow style={{ marginBottom: '13px' }}>
                                <Button variant="contained" color="primary" onClick={() => _downloadPrintableBg()}>
                                    <PrintIcon style={{ marginRight: '5px' }} />
                                    Wydrukuj tło
                                </Button>
                            </OptionRow>
                            <Divider />
                            <Typography style={{ marginTop: '10px' }}>Ustawienia:</Typography>
                            <OptionRow>
                                <Switch
                                    checked={bulkSettings.chooseMode}
                                    onChange={() =>
                                        setBulkSettings({ ...bulkSettings, chooseMode: !bulkSettings.chooseMode })
                                    }
                                    value="checkedB"
                                    color="primary"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                                <Typography>Wybierz pola</Typography>
                            </OptionRow>
                            <Divider />
                            <OptionRow>
                                <Switch
                                    checked={bulkSettings.printWithBackground}
                                    onChange={() =>
                                        setBulkSettings({
                                            ...bulkSettings,
                                            printWithBackground: !bulkSettings.printWithBackground,
                                        })
                                    }
                                    value="checkedB"
                                    color="primary"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                                <Typography>Drukuj z tłem</Typography>
                            </OptionRow>
                            <Divider />
                            {bulkSettings.chooseMode && (
                                <ProductKeyGridWrapper>
                                    <ProductKeyMain>
                                        {productKeysNodes.map((index) => (
                                            <ProductKeyNode
                                                active={bulkSettings.chooseIds.indexOf(index + 1) > -1}
                                                onClick={() => {
                                                    if (bulkSettings.chooseIds.indexOf(index + 1) > -1) {
                                                        let arr = bulkSettings.chooseIds
                                                        arr.splice(bulkSettings.chooseIds.indexOf(index + 1), 1)
                                                        setBulkSettings({
                                                            ...bulkSettings,
                                                            chooseIds: arr,
                                                        })
                                                    } else
                                                        setBulkSettings({
                                                            ...bulkSettings,
                                                            chooseIds: [...bulkSettings.chooseIds, index + 1],
                                                        })
                                                }}
                                                key={index}
                                            >
                                                {bulkSettings.chooseIds.indexOf(index + 1) > -1 && (
                                                    <ProductKeyNodeContent>
                                                        <CheckSymbol src={'/check-symbol.svg'} alt="Check symbol" />
                                                    </ProductKeyNodeContent>
                                                )}
                                            </ProductKeyNode>
                                        ))}
                                    </ProductKeyMain>
                                </ProductKeyGridWrapper>
                            )}
                            {bulkSettings.chooseMode === false && (
                                <OptionRow>
                                    <Typography>Wybierz ilość:</Typography>
                                    <TextField
                                        id="standard-number"
                                        label="Ilość:"
                                        type="number"
                                        value={amountOfBulk}
                                        onChange={(e) => {
                                            if (e.target.value > 0) setAmountOfBulk(e.target.value)
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        style={{ width: '50px' }}
                                    />
                                </OptionRow>
                            )}

                            <PrinterWrapper>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => _onGeneratePrintKeys()}
                                    disabled={
                                        !(daysValue && productKeyData.slug) ||
                                        (!isCSV && bulkSettings.chooseMode && bulkSettings.chooseIds.length < 1)
                                    }
                                >
                                    <PrintIcon style={{ marginRight: '5px' }} />
                                    Pobierz PDF
                                </Button>
                            </PrinterWrapper>
                        </>
                    )}
                </div>
            </PanelMain>
        </Main>
    )
}

export default EditProductKey
