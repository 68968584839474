module.exports = [
    {
        slug: 'PE_ZEGLARZ_JACHTOWY',
        friendlyName: 'PE Żeglarz Jachtowy',
    },
    {
        slug: 'PE_STERNIK_MOTOROWODNY',
        friendlyName: 'PE Sternik motorowodny',
    },
    {
        slug: 'PE_JSM',
        friendlyName: 'PE Jachtowy Sternik Morski',
    },
    {
        slug: 'PE_MSM',
        friendlyName: 'PE Motorowodny Sternik Morski',
    },
]
