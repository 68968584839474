import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

// Material UI
import Button from '@material-ui/core/Button'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import CircularProgress from '@material-ui/core/CircularProgress'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import DeleteIcon from '@material-ui/icons/Delete'

// Config
import config from '../../config'
import products from '../../lib/data/products'

// Imports from other pages
import { PanelMain, Main } from './AddProductKey'

// Styled components
const LoadingWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    display: flex;
    justify-content: center;
    align-items: center;
`
const ProductKeyWrapper = styled.div`
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
`
const ProductKeySpan = styled.span`
    text-align: center;
    font-weight: 400;
    font-size: 30px;
`
const Row = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
`
const BottomRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 20px;
    /* padding-bottom: 20px; */
`
const CenteredRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`
const TagsWraper = styled.div``

const ProductKeyPreview = (props) => {
    const history = useHistory()

    // States
    const [isLoading, setIsLoading] = useState(true)
    const [productKeyData, setProductKeyData] = useState({})

    // Lib
    const { id } = props.match.params

    // Fetching product key data
    const _getProductKeyData = () =>
        fetch(config.apiUrl + `/admin/get-product-key/${id}`, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
            .then((res) =>
                res.json().then((data) => {
                    setProductKeyData(data)
                    setIsLoading(false)
                    console.log(data)
                })
            )
            .catch((err) => console.log(err))

    // Deleting Product Key
    const _onDeleteProductKey = () => {
        fetch(config.apiUrl + `/admin/delete-product-key`, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify({
                id: productKeyData._id,
            }),
        }).then((res) =>
            res.json().then((data) => {
                history.goBack()
            })
        )
    }

    // Did mount
    useEffect(() => {
        _getProductKeyData()
        // eslint-disable-next-line
    }, [])

    return (
        <Main>
            {isLoading ? (
                <LoadingWrapper>
                    <CircularProgress />
                </LoadingWrapper>
            ) : (
                <PanelMain>
                    <div style={{ paddingBottom: '15px' }}>
                        <Button onClick={() => history.push('/product-keys')} color="primary">
                            <KeyboardBackspaceIcon style={{ marginRight: '12px' }} />
                            Wszystkie klucze produktów
                        </Button>
                    </div>
                    <ProductKeyWrapper>
                        <ProductKeySpan>{productKeyData.key}</ProductKeySpan>
                    </ProductKeyWrapper>
                    <Row style={{ marginTop: '30px' }}>
                        <Typography style={{ fontWeight: 600 }}>Informacje o kluczu:</Typography>
                    </Row>
                    <Divider style={{ marginBottom: '20px' }} />
                    <Row>
                        <Typography>Stan:</Typography>
                        <Typography style={{ fontWeight: 600 }}>
                            <Chip
                                style={{
                                    backgroundColor: productKeyData.active ? '#aed581' : '#ff3d00',
                                    color: productKeyData.active ? 'black' : 'white',
                                }}
                                label={productKeyData.active ? 'Aktywny' : 'Nieaktywny'}
                            />
                        </Typography>
                    </Row>
                    <Row>
                        <Typography>Produkt:</Typography>
                        <Typography style={{ fontWeight: 600 }}>
                            {products.filter((a) => a.slug === productKeyData.slug)[0].friendlyName}
                        </Typography>
                    </Row>
                    <Row>
                        <Typography>Tagi:</Typography>
                        <TagsWraper>
                            {productKeyData.tags &&
                                productKeyData.tags.map((tag, index) => (
                                    <Chip style={{ marginRight: '4px' }} size={'medium'} key={index} label={tag} />
                                ))}
                        </TagsWraper>
                    </Row>
                    <Row>
                        <Typography>Ważność licencji:</Typography>
                        <Typography style={{ fontWeight: 600 }}>{productKeyData.licenseLength + 'dni'}</Typography>
                    </Row>
                    {productKeyData.activationData && (
                        <>
                            <Row style={{ marginTop: '30px' }}>
                                <Typography style={{ fontWeight: 600 }}>
                                    Dane użytkownika, który wykorzystał klucz:
                                </Typography>
                            </Row>
                            <Divider style={{ marginBottom: '20px' }} />
                            <Row>
                                <Typography>Email użytkownika:</Typography>
                                <Typography style={{ fontWeight: 600 }}>
                                    {productKeyData.activationData.email}
                                </Typography>
                            </Row>
                            <Row>
                                <Typography>Imię użytkownika:</Typography>
                                <Typography style={{ fontWeight: 600 }}>
                                    {productKeyData.activationData.firstName}
                                </Typography>
                            </Row>
                            <Row>
                                <Typography>Nazwisko użytkownika:</Typography>
                                <Typography style={{ fontWeight: 600 }}>
                                    {productKeyData.activationData.lastName}
                                </Typography>
                            </Row>
                            <Divider />
                            <CenteredRow>
                                <Typography style={{ fontWeight: 500, textAlign: 'center', fontSize: '13px' }}>
                                    {new Date(productKeyData.activationData.date).toString()}
                                </Typography>
                            </CenteredRow>
                        </>
                    )}
                    <Divider />
                    <BottomRow>
                        <Button
                            variant="contained"
                            style={{
                                backgroundColor: '#F44336',
                                color: 'white',
                                marginTop: '10px',
                                marginBottom: '10px',
                            }}
                            onClick={() => _onDeleteProductKey()}
                        >
                            <DeleteIcon />
                            Usuń klucz produktu
                        </Button>
                    </BottomRow>
                </PanelMain>
            )}
        </Main>
    )
}

export default ProductKeyPreview
